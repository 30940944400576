<template>
    <main>
        <FirstPageText/>
    </main>
</template>


<script>
import FirstPageText from '@/components/MainComponents/FirstPageText.vue';

export default {
    name: 'NotFoundPage',
    components: {
        FirstPageText,
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';
</style>