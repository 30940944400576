<template>

    <nav class="container-navbar" @scroll="navbarActive = false" :class="{ active: navbarActive }">
      <div class="navbar-inner">
        <div class="menu">
          <!-- <h3 class="logo">Giesse<span>Plast</span></h3> -->
          <!-- <img class="logo-image" src="../../../public/img/Transparent-03.png" alt="Logo"> -->
          <img class="logo-image" src="@/assets/logo.png" alt="Logo">
          <div class="hamburger-menu" @click="navbarActive = !navbarActive">
            <div class="bar"></div>
          </div>
        </div>
      </div>

      <div class="main-container">
        <div class="main" @click="navbarActive = false">
          <div class="main-content">
            <!-- Video Player -->
            <video id="video-preview" autoplay muted controls playsinline>
              <source class="active" :src="$route.meta.videolink" type="video/mp4">
              <!-- <source src="/videos/stampaggio1.mp4" type="video/mp4">
              <source src="/videos/stampaggio3.mp4" type="video/mp4"> -->
            </video>
            
            <div class="overlay">
              <div class="inner">
                <h2 class="title">{{ $route.meta.title }}</h2>
                <p>{{ $route.meta.subtitle }}</p>
                <button @touchstart="navbarActive == false ? scrollDown() : navbarActive = false" @click="navbarActive == false ? scrollDown() : navbarActive = false" class="btn">Scopri di più <i class="fas fa-angle-down"></i></button>
              </div>
            </div>
          </div>
        </div>

        <!-- Italian Shadows -->
        <div class="shadow one"></div>
        <div class="shadow two"></div>
        <div class="shadow three"></div>
      </div>

      <div class="links">
        <ul>
          <li @click="navbarActive = false" v-for="(navLink, index) in navLinks" :key="index" class="">
            <router-link :active="$route.name === navLink.name" style="--i: 0.05s;" :to="{ name: navLink.name }"> {{ navLink.label }} </router-link>
          </li>
        </ul>
      </div>
    </nav>

</template>

<script>
export default {
    name: 'NavbarComponent',
    data: function() {
        return {
          navbarActive: false,
          navLinks: [
              {
                  name: 'home',
                  label: 'Home',
              },
              {
                  name: 'about',
                  label: 'Chi siamo',
              },
              {
                  name: 'blog',
                  label: 'Blog',
              },
              {
                name: 'contactus',
                label: 'Contattaci'
              }
          ],
        };
    },
    computed: {
        console: () => console,
        window: () => window,
    },
    methods: {
      playVideoPreview() {
        // set variables
        const videoPreview = document.getElementById('video-preview');
        const sources = videoPreview.querySelectorAll('source');
        let indexActiveSource = 0;

        // fix video controls to false
        videoPreview.controls = false;

        // Fade in video function
        function videoFadeIn() {
          videoPreview.style.transition = 'opacity 0.5s';
          videoPreview.style.opacity = 1;
        }

        // FadeOut Function and call Video Fade In Function
        function videoFadeInOut() {
          videoPreview.style.transition = 'opacity 0.5s';
          videoPreview.style.opacity = 0;

          // Attendi 150ms per permettere l'effetto di dissolvenza prima di cambiare la sorgente del video
          setTimeout(function () {
            // Quando l'animazione di dissolvenza è completata, cambiamo la sorgente del video
            videoPreview.src = sources[indexActiveSource].src;

            // Quando il video ha caricato i metadati, eseguiamo l'effetto fade-in
            videoPreview.addEventListener('loadedmetadata', videoFadeIn);

            // Avvia la riproduzione del video
            videoPreview.play();
          }, 150);
        }

        // Function to handle the video end
        function handleVideoEnd() {
          // Rimuovi l'evento 'ended' per evitare chiamate multiple quando il video termina
          videoPreview.removeEventListener('ended', handleVideoEnd);

          // Incrementa l'indice per ottenere la prossima sorgente.
          // Se raggiunge la fine, ritorna al primo elemento.
          indexActiveSource = (indexActiveSource + 1) % sources.length;

          // Esegui l'effetto fade-out e fade-in
          videoFadeInOut();

          // Aggiungi nuovamente l'evento 'ended' dopo aver cambiato la sorgente del video
          videoPreview.addEventListener('ended', handleVideoEnd);
        }

        // Aggiungi un gestore per l'evento 'ended' (fine del video)
        videoPreview.addEventListener('ended', handleVideoEnd);

        // Aggiungi un gestore per l'evento 'play' (inizio della riproduzione)
        videoPreview.addEventListener('play', videoFadeIn);
      },
      scrollDown: function () {
        window.scrollTo({
          top: document.getElementById("scrollhere").offsetTop,
          left: 0,
          behavior: "smooth",
        });
      },
      navScrollListener: function () {
        if (window.scrollY > 250){
          this.navbarActive = false;
        }
      },
    },
    mounted: function() {
      this.playVideoPreview();
      window.addEventListener('scroll', this.navScrollListener);
    },
    beforeUnmount: function () {
      window.removeEventListener('scroll', this.navScrollListener);
    },
  }
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

.main-content {
  min-height: 100vh;
  width: 100%;
  background-image: linear-gradient(180deg, black 0%, $secondary-color 74%);
  position: relative;
  z-index: 0;
  #video-preview{
    background-image: linear-gradient(180deg, black 0%, $secondary-color 74%);
    position: absolute;
    right: 0; 
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
    width: 100% !important;
    // height: auto !important;
    height: 100% !important;
    z-index: -100;
    background-size: cover;
    background-position: center;
    object-position: center;
    object-fit: cover;
    overflow: hidden;

    pointer-events: none;
  }
}

.container-navbar {
  min-height: 100vh;
  width: 100%;
  background-color: $secondary-color;
  background-image: linear-gradient(180deg, black 0%, $secondary-color 74%);
  overflow-x: hidden;
  transform-style: preserve-3d;
}

.navbar-inner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  height: 3rem;
}

.menu {
  width: 90%;
  margin: auto;
  padding: 2rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $third_color;
}

.logo-image{
  height: 5rem;
}
.logo {
  font-size: 1.1rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 2px;
  line-height: 4rem;
}

.logo span {
  font-weight: 300;
}

.hamburger-menu {
  height: 4rem;
  width: 3rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bar {
  width: 1.9rem;
  height: 1.5px;
  border-radius: 2px;
  background-color: $third_color;
  transition: 0.5s;
  position: relative;
}

.bar:before,
.bar:after {
  content: "";
  position: absolute;
  width: inherit;
  height: inherit;
  background-color: $third_color;
  transition: 0.5s;
}

.bar:before {
  transform: translateY(-9px);
}

.bar:after {
  transform: translateY(9px);
}

.main {
  position: relative;
  width: 100%;
  left: 0;
  z-index: 5;
  overflow: hidden;
  transform-origin: left;
  transform-style: preserve-3d;
  transition: 0.5s;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba($color: $secondary_color, $alpha: 0.39);
  display: flex;
  justify-content: center;
  align-items: center;
}

.inner {
  max-width: 35rem;
  text-align: center;
  color: #fff;
  padding: 0 2rem;
}

.title {
  font-size: 2.7rem;
  text-transform: capitalize;
}

.btn {
  margin-top: 1rem;
}

.container-navbar.active .bar {
  transform: rotate(360deg);
  background-color: transparent;
}

.container-navbar.active .bar:before {
  transform: translateY(0) rotate(45deg);
}

.container-navbar.active .bar:after {
  transform: translateY(0) rotate(-45deg);
}

.container-navbar.active .main {
  animation: navbar-main-animation 0.5s ease;
  cursor: pointer;
  transform: perspective(1300px) rotateY(20deg) translateZ(520px) scale(0.4);
}

@keyframes navbar-main-animation {
  from {
    transform: translate(0);
  }

  to {
    transform: perspective(1300px) rotateY(20deg) translateZ(520px) scale(0.4);
  }
}

.links {
  position: absolute;
  width: 30%;
  right: 0;
  top: 0;
  height: 100vh;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul {
  list-style: none;
}

.links a {
  text-decoration: none;
  color: $third_color;
  padding: 0.7rem 0;
  display: inline-block;
  font-size: 1rem;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: 0.3s;
  opacity: 0;
  transform: translateY(10px);
  animation: hide 0.5s forwards ease;
}

.links a:hover {
  color: $primary_color;
}

.links a.router-link-exact-active{
  font-weight: 600;
  color: rgba($color: $primary_color, $alpha: 1);
}

.container-navbar.active .links a {
  animation: appear 0.5s forwards ease var(--i);
}

@keyframes appear {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@keyframes hide {
  from {
    opacity: 1;
    transform: translateY(0px);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.shadow {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  transform-style: preserve-3d;
  transform-origin: left;
  transition: 0.5s;
}

.shadow.one {
  z-index: -1;
  opacity: 0.8;
  background-color: #CD212A;
}

.shadow.two {
  z-index: -2;
  opacity: 0.7;
  background-color: #F4F5F0;
}

.shadow.three {
  z-index: -3;
  opacity: 0.6;
  background-color: #008C45;
}

.container-navbar.active .shadow.one {
  animation: shadow-one 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(490px) scale(0.4);
}

@keyframes shadow-one {
  0% {
    transform: translate(0);
  }

  5% {
    transform: perspective(1300px) rotateY(20deg) translateZ(520px) scale(0.3);
  }

  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(490px) scale(0.4);
  }
}

.container-navbar.active .shadow.two {
  animation: shadow-two 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(460px) scale(0.4);
}

@keyframes shadow-two {
  0% {
    transform: translate(0);
  }

  20% {
    transform: perspective(1300px) rotateY(20deg) translateZ(520px) scale(0.3);
  }

  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(460px) scale(0.4);
  }
}

.container-navbar.active .shadow.three {
  animation: shadow-three 0.6s ease-out;
  transform: perspective(1300px) rotateY(20deg) translateZ(430px) scale(0.4);
}

@keyframes shadow-three {
  0% {
    transform: translate(0);
  }

  20% {
    transform: perspective(1300px) rotateY(20deg) translateZ(520px) scale(0.3);
  }

  100% {
    transform: perspective(1300px) rotateY(20deg) translateZ(430px) scale(0.4);
  }
}

// .container-navbar.active .main:hover + .shadow.one {
//   transform: perspective(1300px) rotateY(20deg) translateZ(470px) scale(0.4);
// }

.container.active .main:hover {
  transform: perspective(1300px) rotateY(20deg) translateZ(340px) scale(0.5);
}

@media (max-width: 1368px) {
  .main-content {
    height: 100vh;
    // #video-preview{
    //   background-image: linear-gradient(180deg, black 0%, $secondary-color 74%);
    //   position: absolute; 
    //   right: 0; 
    //   bottom: 0;
    //   min-width: 100%; 
    //   min-height: 100%;
    //   width: 100%; 
    //   height: auto; 
    //   z-index: -100;
    //   background-size: cover;
    //   overflow: hidden;
    // }
  }
  .container-navbar.active .main, .shadow, .container-navbar.active .shadow.one, .container-navbar.active .shadow.two, .container-navbar.active .shadow.three {
    display: none;
  }
  .links {
    width: 100%;
  }
}


</style>