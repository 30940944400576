import { createRouter, createWebHistory } from 'vue-router'
import HomePage from '@/pages/HomePage.vue'
import AboutUsPage from '@/pages/AboutUsPage.vue'
import BlogPage from '@/pages/BlogPage.vue'
import ContactUsPage from '@/pages/ContactUsPage.vue'
import NotFoundPage from '@/pages/NotFoundPage.vue'


const routes = [
  {
      path: "/",
      name: "home",
      component: HomePage,
      meta: {
          title: 'Benvenuto',
          subtitle: "Benvenuti sul nostro sito web. Siamo lieti di presentarvi i nostri servizi e le soluzioni innovative che offriamo. Esplorate le nostre competenze e scoprite come possiamo supportare il successo del vostro progetto. Buona navigazione.",
          videolink: '/videos/stampaggio1.mp4',
          firstText: "Giesse Plast è un'azienda fondata nel 1996 con l'obiettivo di fornire servizi e prodotti all'avanguardia per soddisfare le esigenze in evoluzione di tutti i nostri clienti per svariati settori. Specializzata in stampaggio ad iniezione plastica, pressofusione zamak, progettazione, realizzazione stampi e confezionamento articoli, l'azienda si distingue per la sua dedizione all'innovazione e all'eccellenza. Fornendo a tutti i suoi clienti una realtà unica per accompagnarli in tutte le loro esigenze, dallo sviluppo alla realizzazione del particolare finito, pronto per il mercato odierno."
      }
  },
  {
      path: "/about",
      name: "about",
      component: AboutUsPage,
      meta: {
          title: 'Chi siamo',
          subtitle: "La nostra azienda si distingue per l'eccellenza in stampaggio ad iniezione plastica e pressofusione zama, unita a competenze nella progettazione 3D, realizzazione di stampi e confezionamento di articoli.",
          videolink: '/videos/stampaggio2.mp4',
          firstText: "La nostra missione è fornire prodotti di eccellenza, attraverso un impegno costante nella qualità, nella tecnologia e nel servizio al cliente. Grazie alla dedizione e alla passione del nostro team, Giesse Plast continua a crescere e innovare, mantenendo vivi i valori e la visione.",
      }
  },
  {
      path: "/blog",
      name: "blog",
      component: BlogPage,
      meta: {
          title: 'Il nostro blog',
          subtitle: "Benvenuti nel blog di Giesse Plast! Qui troverete le ultime novità e approfondimenti relativi alla nostra azienda.",
          videolink: '/videos/stampaggio3.mp4',
          firstText: "Che siate professionisti del settore, partner commerciali o semplicemente curiosi di sapere di più sul mondo della plastica e della pressofusione, il nostro blog è qui per voi. Unitevi alla nostra comunità e rimanete connessi con Giesse Plast!",
      }
  },
  {
      path: "/contactus",
      name: "contactus",
      component: ContactUsPage,
      meta: {
          title: 'Entra in contatto',
          subtitle: "Siamo qui per aiutarti! Se hai domande, necessiti di assistenza o desideri ulteriori informazioni sui nostri prodotti e servizi, non esitare a contattarci. Il nostro team è a tua disposizione e pronto a rispondere alle tue esigenze.",
          videolink: '/videos/contactus.mp4',
          firstText: "Che tu abbia una domanda, un commento o necessiti di assistenza, il nostro team è pronto a rispondere alle tue esigenze. Siamo qui per garantire che tu abbia tutte le informazioni necessarie e per offrirti il miglior supporto possibile. Non esitare a contattarci tramite telefono, email o compilando il modulo sottostante. Ci impegniamo a rispondere a tutte le richieste nel minor tempo possibile. Il cliente è la nostra priorità!",
      }
  },
  {
      path: '/:catchAll(.*)',
      name: "not-found",
      component: NotFoundPage,
      meta: {
          title: 'Errore 404',
          subtitle: "Ops, sembra che non troviamo quello che stai cercando.",
          videolink: '/videos/404vid.mp4',
          firstText: 'Contenuto non trovato',
      }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
