<template>
    <section id="our-services" @click="handleOutsideClick">
      <div class="container-custom">
        <div class="desc">
          <div class="heading">
            <h1 style="font-weight: 300;">
              I <span style="font-weight: 600;">NOSTRI</span>
              <span style="font-weight: 300;">SERVIZI</span>
            </h1>
          </div>
          <div class="service-desc-container">
            <p v-if="selectedService">
              {{ selectedService.desc }}
            </p>
            <p v-else>
                La nostra azienda è specializzata nello sviluppo di articoli di alta qualità, progettati per soddisfare tutte le esigenze e le aspettative dei nostri clienti. Grazie a un team di professionisti esperti e creativi, siamo in grado di offrire soluzioni innovative e personalizzate, garantendo sempre risultati eccellenti.
                Ogni progetto è gestito con un'attenzione meticolosa ai dettagli, all'efficienza e alla richiesta del cliente, assicurando consegne puntuali e prodotti che rispecchiano i più alti standard del settore.
                La nostra missione è quella di creare articoli che non solo rispondano alle necessità attuali, ma che anticipino le tendenze future, sostenendo così la crescita e il successo a lungo termine dei nostri clienti.
            </p>
          </div>
        </div>
        <div class="services">
          <div class="column" v-for="(column, index) in servicesData" :key="index">
            <div 
              class="service" 
              v-for="(service, idx) in column" 
              :key="idx"
              @click.stop="selectService(service)"
              :class="{ active: service === selectedService }"
            >
              <div class="service-desc">
                <i :class="service.iconClass"></i>
                <p class="service-txt">{{ service.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="clear-fix"></div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'OurServicesComponent',
    data() {
      return {
        servicesData: [
          [
            { 
                iconClass: 'service-icon fas fa-flask',
                text: 'Analisi progettuale',
                desc: 'Dettaglio dell\'analisi progettuale' 
            },
            { 
                iconClass: 'service-icon fa-solid fa-comments', 
                text: 'Confronto tecnico',
                desc: 'Nel confronto tecnico troviamo soluzioni alle esigenze del cliente, analizziamo dettagliatamente i requisiti e le opzioni disponibili. Attraverso incontri di lavoro collaborativo e valutazioni approfondite, identifichiamo la migliore soluzione tecnica, assicurando un\'implementazione efficace e soddisfacente per il cliente.'
            },
            { 
                iconClass: 'service-icon far fa-lightbulb', 
                text: "Studio dell'idea",
                desc: 'Accompagniamo i clienti dalla concezione all\'implementazione di nuovi progetti. Attraverso incontri dedicati e analisi approfondite, trasformiamo idee iniziali in strategie concrete. Il processo include valutazioni di mercato, definizione di obiettivi e creazione di piani di sviluppo dettagliati, assicurando che ogni progetto sia pronto nei tempi concordati e secondo le esigenze di mercato.' 
            },
          ],
          [
            { 
                iconClass: 'service-icon fa-solid fa-gear', 
                text: 'Produzione del particolare',
                desc: 'Dettaglio della produzione del particolare' 
            },
            { 
                iconClass: 'service-icon fas fa-wrench', 
                text: 'Collaudi di produzione',
                desc: 'Dettaglio dei collaudi di produzione' 
            },
            { 
                iconClass: 'service-icon fa-solid fa-industry', 
                text: 'Fattibilità industriale',
                desc: 'Dettaglio della fattibilità industriale' 
            },
          ],
          [
            { 
                iconClass: 'service-icon fas fa-tasks', 
                text: 'Controllo qualità',
                desc: 'Dettaglio del controllo qualità' 
            },
            { 
                iconClass: 'service-icon fas fa-certificate',
                text: 'Certificazioni articoli',
                desc: 'Dettaglio delle certificazioni articoli' 
            },
            {
                iconClass: 'service-icon fa-solid fa-boxes-stacked', 
                text: 'Packaging e Logistica',
                desc: 'Dettaglio del packaging e logistica' 
            },
          ],
        ],
        selectedService: null,
      };
    },
    methods: {
      selectService(service) {
        this.selectedService = service;
      },
      handleOutsideClick(event) {
        if (this.$el != event.target) {
          this.selectedService = null;
        }
      },
    },
    mounted() {
      document.addEventListener('click', this.handleOutsideClick);
    },
    beforeUnmount() {
      document.removeEventListener('click', this.handleOutsideClick);
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import '@/assets/sass/partials/variables';
  @import '@/assets/sass/partials/generals';
  
  .heading h1::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: $primary_color;
  }
  
  .clear-fix {
    clear: both;
  }
  
  #our-services {
    background-image: linear-gradient(180deg, $secondary-color 0%, black 53%, $secondary_color 83%);
    min-height: 100vh;
    width: 100%;
    padding-top: 25vh;
  }
  
  /* OUR SERVICES LEFT SIDE */
  
  .desc {
    width: 30%;
    margin-right: 20%;
    float: left;
  }
  
  .desc .heading h1 {
    letter-spacing: 1.4px;
    margin-bottom: 30px;
    color: $third_color;
    font-weight: 600;
    text-transform: capitalize;
    margin-bottom: 40px;
    position: relative;
  }
  
  .desc .service-desc-container {
    color: $fourth_color;
  }
  
  /* OUR SERVICES RIGHT SIDE */
  
  .services {
    width: 50%;
    float: left;
    margin: auto;
  }
  
  .services .column {
    float: left;
    margin-right: 60px;
    transform: rotate(45deg);
    transition: transform 0.3s ease;
  }
  
  .services .column .service {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120px;
    height: 120px;
    background: rgba($third_color, 0.3);
    transition: all 0.1s ease;
    margin-bottom: 7px;
    cursor: pointer;
  }
  
  .services .column .service:hover,
  .services .column .service.active {
    background: $primary_color;
    color: $third_color;
  }
  
  .services .column .service:hover .service-icon,
  .services .column .service.active .service-icon {
    color: $third_color;
  }
  
  .services .column .service:hover .service-desc,
  .services .column .service.active .service-desc {
    color: $third_color;
  }
  
  .services .column .service .service-desc {
    transform: rotate(-45deg);
    text-align: center;
    text-transform: uppercase;
    font-size: 10px;
    font-weight: bold;
    transition: transform 0.3s ease;
  }
  
  .services .column .service .service-desc .service-icon {
    font-size: 20px;
    margin-bottom: 5px;
    color: $primary_color;
  }
  
  .services .column .service:hover .service-desc .service-icon,
  .services .column .service.active .service-desc .service-icon {
    color: $third_color;
  }
  
  /* media query */
  
  @media all and (max-width: 967px) {
    #our-services {
      padding-top: 60px;
    }
  
    .desc {
      width: 100%;
      float: none;
      margin-bottom: 30px;
    }
  
    .services {
      width: 100%;
    }
  
    .services .column {
      transform: rotate(0deg);
      margin-right: 5px;
    }
  
    .services .column .service .service-desc {
      transform: rotate(0deg);
    }
  }
  </style>
  