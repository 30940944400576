<template>
    <div class="scrolltop-container">
        <button v-if="showButton" 
                @touchstart.prevent="scrollTop"
                @click.prevent="scrollTop"
                :class="{ 'disabled': isScrolling }" 
                class="btn"
                id="scrolltop-button">
            <i class="fas fa-angle-up"></i>
        </button>
    </div>
    
</template>

<script>
export default {
    name: 'ScrollTopComponent',
    data() {
        return {
            showButton: false,
            isScrolling: false
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll() {
            this.showButton = window.scrollY > 100;
        },
        scrollTop() {
            if (this.isScrolling) return;
            
            this.isScrolling = true;
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
            
            setTimeout(() => {
                this.isScrolling = false;
            }, 1800);
        }
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

.scrolltop-container {
    position: fixed;
    z-index: 9999999;
    bottom: 15px;
    right: 15px;
    cursor: pointer;
    .btn {
        padding: 0.2rem 0.5rem;
        i {
            font-size: 30px;
            color: $third_color;
        }
    }
    .btn.disabled{
        opacity: 0.1;
    }
}
</style>
