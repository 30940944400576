<template>
    <main>
        <FirstPageText/>
        <TimelineComponent/>
        <FaqComponent/>
    </main>
</template>


<script>
import FirstPageText from '@/components/MainComponents/FirstPageText.vue';
import TimelineComponent from '@/components/MainComponents/TimelineComponent.vue';
import FaqComponent from '@/components/MainComponents/FaqComponent.vue';

export default {
    name: 'AboutUsPage',
    components: {
        FirstPageText,
        TimelineComponent,
        FaqComponent,
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';
</style>