<template>
    <main>
        <UnderConstructionComponent/>
    </main>
</template>


<script>
import UnderConstructionComponent from '@/components/MainComponents/UnderConstructionComponent.vue';

export default {
    name: 'BlogPage',
    components: {
        UnderConstructionComponent,
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';
</style>