<template>

    <section class="section-bg">
        <div class="cube-container">
            <div class="content">
                <h1 style="font-weight: 300;">DI COSA CI <span style="font-weight: 600;">OCCUPIAMO</span> </h1>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Cum maxime impedit nisi aspernatur quidem explicabo, nobis rem consequatur iste itaque repellat quibusdam nostrum omnis, facilis delectus, voluptas nemo quisquam id.
                </p>
            </div>

            <div class="swiper">
              <div class="swiper-wrapper">
                <div v-for="(swiperSlide, index) in swiperSlides" :key="index" class="swiper-slide">
                  <img :src="swiperSlide.img" :alt="swiperSlide.heading + ' image'" />
                  <div class="overlay">
                    <h1>{{ swiperSlide.heading }}</h1>
                    <p>{{ swiperSlide.description }}</p>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </section>

</template>

<script>
import Swiper from 'swiper';
import { EffectCube, Autoplay } from 'swiper/modules';

export default {
  name: 'CubeCarousel',
  data: function() {
      return {
        swiper: null,
        swiperSlides: [
          {
            id: 0,
            img: "/images/stampaggio-plastica.jpeg",
            heading: "Stampaggio ad iniezione",
            description: "Il nostro reparto di stampaggio ad iniezione di materie plastiche, dispone di 15 presse con un tonnellaggio da 55 a 1250, garantendo alta qualità e flessibilità ad ogni esigenza produttiva per tutti i settori."
          },
          {
            id: 1,
            img: "/images/stampaggio-zama.jpeg",
            heading: "Stampaggio pressofusione",
            description: "Scopri l'eccellenza dello stampaggio pressofusione zamak di Giesse Plast: precisione e qualità con presse da 50 a 135 tonnellate, garantendo soluzioni durevoli e personalizzate per ogni esigenza. Innovazione e affidabilità al vostro servizio."
          },
          {
            id: 2,
            img: "/images/officina-stampi.jpeg",
            heading: "Realizzazione stampi",
            description: "Giesse Plast eccelle nella realizzazione di stampi con macchine a 3 e 5 assi, offrendo precisione e qualità superiore. Soluzioni su misura per tutte le esigenze di produzione, con un impegno costante nell'innovazione.."
          },
          {
            id: 3,
            img: "/images/confezionamento.jpeg",
            heading: "Confezionamento articoli",
            description: "Il reparto confezionamento di Giesse Plast offre efficienza e precisione con 4 centri di lavoro dedicati. Soluzioni personalizzate per garantire la massima qualità e cura dei dettagli in ogni fase del confezionamento."
          },
        ],
      };
  },
  mounted: function(){
    this.swiper = new Swiper(".swiper", {
      modules: [EffectCube, Autoplay],
      effect: "cube",
      grabCursor: true,
      loop: true,
      speed: 3000,
      cubeEffect: {
        shadow: true,
        slideShadows: true,
        shadowOffset: 10,
        shadowScale: 0.99,
      },
      autoplay: {
        delay: 9000,
        pauseOnMouseEnter: true,
      },
    });
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.section-bg {
  background-image: linear-gradient(180deg, $secondary-color 0%, black 53%, $secondary_color 83%);
  
  background-repeat: no-repeat;
  background-size: cover;
}


.cube-container {
  display: grid;
  grid-template-columns: 50% 45%;
  place-items: center;
  gap: 60px;
  min-height: 100vh;
  padding: 20px 60px;
}

.content {
  max-width: 100%;
}

.content h1 {
  position: relative;
  font-weight: 600;
  // line-height: 1.2;
  // letter-spacing: 1px;
  margin-bottom: 36px;
  color: $third_color;
}

.content h1:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: $primary_color;
}

.content p {
  font-size: clamp(1rem, 4vw, 1.1rem);
  font-weight: 300;
  line-height: 1.5;
  margin-bottom: 30px;
  color: $fourth_color;
}

.swiper {
  position: relative;
  width: 500px;
  height: 500px;
}

.swiper-slide {
  position: relative;
  background-position: center;
  background-size: cover;
  border: 1px solid rgba($primary_color, 0.3);
  user-select: none;
}

.dark-text {
  color: $secondary_color;
}

.swiper-slide img {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 35%;
  padding: 0.5rem 0.9rem;
  background: rgba($secondary_color, 0.3);
  backdrop-filter: blur(23px);
  -webkit-backdrop-filter: blur(20px);
  border-top: 1px solid rgba($primary_color, 0.3);
  color: $third_color;
}

.overlay h1 {
  font-size: clamp(1rem, 3vw, 1.5rem);
  font-weight: 600;
}

.overlay p {
  font-size: clamp(0.7rem, 3vw, 0.9rem);
  font-weight: 300;
  line-height: 1.3;
}

.ratings {
  display: flex;
  column-gap: 10px;
  margin-top: -6px;
}

.ratings span {
  font-size: clamp(0.8rem, 4vw, 0.9rem);
  font-weight: 300;
}

@media (max-width: 1050px) {
  .swiper {
    width: 390px;
    height: 390px;
  }
}

@media (max-width: 930px) {
  .cube-container {
    grid-template-columns: 100%;
    grid-template-rows: 55% 40%;
    grid-template-areas:
      "slider"
      "content";
    place-items: center;
    gap: 64px;
    padding: 60px;
  }

  .swiper {
    grid-area: slider;
  }

  .content {
    grid-area: content;
    text-align: center;
  }

  .content h1 {
    margin-bottom: 20px;
  }
}

@media (max-width: 470px) {
  .cube-container {
    padding: 40px 40px 60px;
  }

  .swiper {
    width: 350px;
    height: 350px;
  }

  .content h1:after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    bottom: -15px;
    height: 2px;
    width: 50px;
    background: $primary_color;
  }
}

</style>