<template>
  <div class="container-custom">
    <h1>Domande Frequenti</h1>
    <div class="faq-container">
      <div class="faq-item" v-for="(item, index) in faqItems" :key="index">
        <button class="faq-button" @click="toggleExpand(index)">
          <span class="faq-title" :class="{ 'expanded': item.expanded }">{{ item.title }}</span>
          <span class="icon" :class="{ 'minus': item.expanded }"></span>
        </button>
        <div class="faq-content" :class="{ 'visible': item.expanded }">
          <p>{{ item.content }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FaqComponent',
  data() {
    return {
      faqItems: [
        {
          title: 'Why is the moon sometimes out during the day?',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          expanded: false,
        },
        {
          title: 'Why is the sky blue?',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          expanded: false,
        },
        {
          title: 'Will we ever discover aliens?',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          expanded: false,
        },
        {
          title: 'How much does the Earth weigh?',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          expanded: false,
        },
        {
          title: 'How do airplanes stay up?',
          content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Elementum sagittis vitae et leo duis ut. Ut tortor pretium viverra suspendisse potenti.',
          expanded: false,
        },
      ],
    };
  },
  methods: {
    toggleExpand(index) {
      this.faqItems.forEach((item, i) => {
        if (i !== index) {
          item.expanded = false;
        }
      });
      this.faqItems[index].expanded = !this.faqItems[index].expanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

* {
  box-sizing: border-box;
  &::before,
  &::after {
    box-sizing: border-box;
  }
}

h1{
  text-align: center;
  margin: 5rem 0;
}

.faq-container {
  .faq-item {
    border-bottom: 1px solid $fourth_color;
    .faq-button {
      position: relative;
      display: block;
      text-align: left;
      width: 100%;
      padding: 1em 0;
      color: $third_color;
      font-size: 1.15rem;
      font-weight: 400;
      border: none;
      background: none;
      outline: none;
      &:hover{
        cursor: pointer;
        color: $primary_color;
      }
      &:focus {
        cursor: pointer;
        &::after {
          cursor: pointer;
          color: $primary_color;
          border: 1px solid $primary_color;
        }
      }
      .faq-title {
        display: inline-block;
        // padding: 1em 1.5em 1em 0;
        width: 90%;
      }
      .faq-title.expanded{
        color: $primary_color;
        font-weight: 700;
      }
      .icon {
        display: inline-block;
        position: absolute;
        top: 18px;
        right: 0;
        width: 22px;
        height: 22px;
        border: 1px solid;
        border-radius: 0;
        color: $primary_color;
        &::before {
          display: block;
          position: absolute;
          content: '';
          top: 9px;
          left: 5px;
          width: 10px;
          height: 2px;
          background: $third_color;
        }
        &::after {
          display: block;
          position: absolute;
          content: '';
          top: 5px;
          left: 9px;
          width: 2px;
          height: 10px;
          background: $third_color;
        }
      }
      .icon.minus {
        display: inline-block;
        position: absolute;
        top: 18px;
        right: 0;
        width: 22px;
        height: 22px;
        border: 1px solid;
        &::before {
          display: block;
          position: absolute;
          content: '';
          top: 9px;
          left: 5px;
          width: 10px;
          height: 2px;
          background: $third_color;
        }
        &::after {
          display: none;
        }
      }
    }
    .faq-content {
      overflow: hidden;
      transition: 0.18s ease-in-out;
      max-height: 0;
      padding: 0 1.5em;
      opacity: 0;

      color: $third_color;
    }
    .faq-content.visible {
      opacity: 1;
      max-height: 100vh;
      padding: 1em 1.5em;
    }
  }
}
</style>
