<template>
    <footer class="footer-section">
        <div class="container-custom">
            <div class="footer-cta pt-5 pb-5">
                <div class="row">
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-map-marker-alt"></i>
                            <div class="cta-text">
                                <h4>Dove siamo</h4>
                                <a href="https://goo.gl/maps/HvNbEsoEkr6pA8FbA" target="_blank" style="text-decoration: none;"><span>Via Vincenzo Molaroni, 18 - 61122 (PU)</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="fas fa-phone"></i>
                            <div class="cta-text">
                                <h4>Chiamaci</h4>
                                <a href="tel:+390721201060" target="_blank" style="text-decoration: none;"><span>+39 0721 201060</span></a>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-4 mb-30">
                        <div class="single-cta">
                            <i class="far fa-envelope"></i>
                            <div class="cta-text">
                                <h4>Contattaci</h4>
                                <a href="mailto:info@giesseplast.com" target="_blank" style="text-decoration: none;"><span>info@giesseplast.com</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-content pt-5 pb-5">
                <div class="row">
                    <div class="mid-col col-xl-4 col-lg-4">
                        <div class="footer-widget">
                            <div class="footer-logo">
                                <a href="https://giesseplast.com/"><img src="@/assets/logo.png" class="img-fluid" alt="logo"></a>
                            </div>
                            <div class="footer-text">
                                <p>Dal 1996, leader in zona per stampaggio ad iniezione plastica e pressofusione zamak, progettazione, realizzazione stampi e confezionamento articoli.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mid-col col-xl-4 col-lg-4 col-md-6">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Link utili</h3>
                            </div>
                            <ul>
                              <li v-for="(footLink, index) in footLinks" :key="index">
                                <router-link @click="scrollToTop()" :active="$route.name === footLink.name" style="--i: 0.05s;" :to="{ name: footLink.name }"> {{ footLink.label }} </router-link>
                              </li>
                            </ul>
                        </div>
                    </div>
                    <div class="mid-col col-xl-4 col-lg-4 col-md-6">
                        <div class="footer-widget">
                            <div class="footer-widget-heading">
                                <h3>Contatti</h3>
                            </div>
                            <div class="footer-text mb-25">
                                <p>Entra in contatto con noi, spiegaci la tue esigenze e permettici di aiutarti.</p>
                            </div>
                            <button
                              @touchstart="scrollAndChangeRouteToContactForm"
                              @click="scrollAndChangeRouteToContactForm" 
                              class="btn" style="width: 100%; margin-bottom: 30px;">
                                Contattaci
                            </button>
                            <div class="footer-social-icons">
                                <a class="grow-pulse" href="https://www.facebook.com/giesseplast" target="_blank"><i class="fa-brands fa-facebook-f facebook-bg"></i></a>
                                <a class="grow-pulse" href="https://www.instagram.com/giesseplast" target="_blank"><i class="fa-brands fa-instagram instagram-bg"></i></a>
                                <a class="grow-pulse" href="https://x.com/GiessePlast" target="_blank"><i class="fa-brands fa-x-twitter twitter-bg"></i></a>
                                <a class="grow-pulse" href="https://www.linkedin.com/company/giesseplast" target="_blank"><i class="fa-brands fa-linkedin-in linkedin-bg"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <div class="container-custom">
                <div class="row">
                    <div class="col-xl-6 col-lg-6 text-left text-lg-left">
                        <div class="copyright-text">
                            <p>Copyright &copy; 2023, All Rights Reserved <a href="http://www.giesseplast.com/"><span style="font-weight: 600;">GIESSE</span><span>PLAST s.r.l.</span></a></p>
                        </div>
                    </div>
                    <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                        <div class="footer-menu">
                            <ul>
                                <li>
                                  <a href="https://www.iubenda.com/termini-e-condizioni/91204990" target="_blank" title="Termini e Condizioni">Termini e Condizioni</a>
                                </li>
                                <li>
                                  <a href="https://www.iubenda.com/privacy-policy/91204990" target="_blank" title="Privacy Policy">Privacy Policy</a>
                                </li>
                                <li>
                                  <a href="https://www.iubenda.com/privacy-policy/91204990/cookie-policy" target="_blank" title="Cookie Policy">Cookie Policy</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
  name: 'FooterLayout',
  data () {
      return {
          footLinks: [
            {
                name: 'home',
                label: 'Home',
            },
            {
                name: 'about',
                label: 'Chi siamo',
            },
            {
                name: 'blog',
                label: 'Blog',
            },
            {
              name: 'contactus',
              label: 'Contattaci'
            }
        ],
      }
  },
  methods: {
    scrollAndChangeRouteToContactForm: function () {
      if (this.$route.name != 'contactus' ){
        this.$router.push('/contactus');
      }

      setTimeout(() => {
          window.scrollTo({
            top: document.getElementById("contactform").offsetTop,
            left: 0,
            behavior: "smooth",
        });
      }, 100);
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
}
a {
  text-decoration: none;
}
.footer-section {
  background-color: $secondary-color;
  background-image: linear-gradient(180deg, $secondary-color 0%, black 74%);
  position: relative;
}
.footer-cta {
  border-bottom: 1px solid #373636;
}
.single-cta{
    margin: 1rem 1em;

    display: flex;
    flex-flow: row nowrap;
    justify-content: left;
    align-items: center;
}
.single-cta i {
  color: $primary_color;
  font-size: 30px;
  float: left;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: $third_color;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: $fourth_color;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
    max-width: 60%;
}
.footer-text p {
    margin-bottom: 14px;
    font-size: 14px;
    color: $fourth_color;
    line-height: 28px;
}
.footer-social-icons{
    align-self: flex-end;
    margin-top: auto;
}
.footer-social-icons span {
  color: $third_color;
  display: block;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 20px;
}
.footer-social-icons a {
  color: $third_color;
  font-size: 16px;
  margin-right: 15px;
  cursor: pointer;
}
.footer-social-icons i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 3%;
}
.facebook-bg{
  background: #3B5998;
  color: white;
}
.twitter-bg{
  // background: #55ACEE;
  background: white;
  color: black;
}
.linkedin-bg{
  background: #0a66c2;
}
.instagram-bg{
  background: #d6249f;
  background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%);
  color: white;
}
.footer-widget{
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}
.footer-widget-heading h3 {
  color: $third_color;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  margin-bottom: 40px;
  position: relative;
}
.footer-widget-heading h3::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -15px;
  height: 2px;
  width: 50px;
  background: $primary_color;
}
.mid-col{
    padding: 1em;
}
.footer-widget ul{
    list-style-type: none;
}
.footer-widget ul li {
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover{
  color: $primary_color;
  text-decoration: none;
}
.footer-widget ul li a {
  color: $fourth_color;
  text-transform: capitalize;
}
.copyright-area{
  background: $secondary_color;
  padding: 25px 0;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: $fourth_color;
}
.copyright-text p a{
  color: $primary_color;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a,
.router-link-exact-active{
  color: $primary_color !important;
  text-decoration: none;
}
.footer-menu li a {
  font-size: 14px;
  color: $fourth_color;
}
</style>