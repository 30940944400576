<template>
    <section id="timeline">
      <h1>A Flexbox Timeline</h1>
      <p class="leader">All cards must be the same height and width for space calculations on large screens.</p>
      <div class="demo-card-wrapper">
        <div
          v-for="(timelineElement, index) in timelineElements"
          ref="containers"
          :key="index"
          class="fade-element"
          :class="['demo-card', `demo-card--step${index + 1}`]"
        >
          <div class="head">
            <div class="number-box">
              <span>{{ timelineElement.year }}</span>
            </div>
            <h2>{{ timelineElement.title }} <span class="small">{{ timelineElement.subtitle }}</span></h2>
          </div>
          <div class="body">
            <img :src="timelineElement.image" :alt="timelineElement.alt">
            <p>{{ timelineElement.description }}</p>
          </div>
        </div>
      </div>
    </section>
  </template>
  
  <script>
  export default {
    name: 'TimelineComponent',
    data() {
      return {
        timelineElements: [
          {
            year: '1996',
            title: "L'inizio",
            subtitle: "Nasce Giesse Plast s.r.l.",
            description: "L'idea di Giesse Plast nasce nel 1996 dall'idea delle famiglie Galeazzi e Smacchia, che decidono di aprire uno stabilimento dedicato allo stampaggio ad iniezione di materie plastiche.",
            image: "http://placehold.it/1000x500",
            alt: "Graphic"
          },
          {
            year: '1999',
            title: "Primi passi",
            subtitle: "Giesse Plast cresce",
            description: "Distinguendosi per il suo impegno ed eccellenza con i suoi primi clienti e lavori, gli sforzi vengono riconosciuti e Giesse si trasferisce dal suo stabilimento in affitto ad uno stabilimento di proprietà dove a seguito delle esigenze in crescita viene creato anche il reparto Realizzazione Stampi.",
            image: "http://placehold.it/1000x500",
            alt: "Graphic"
          },
          {
            year: '2000',
            title: "Orizzonti",
            subtitle: "Adaptation",
            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus consequatur, laudantium odio dolorum laboriosam.",
            image: "http://placehold.it/1000x500",
            alt: "Graphic"
          },
          {
            year: '2010',
            title: "Subtitle",
            subtitle: "Consistency",
            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus consequatur, laudantium odio dolorum laboriosam.",
            image: "http://placehold.it/1000x500",
            alt: "Graphic"
          },
          {
            year: '2021',
            title: "Subtitle",
            subtitle: "Conversion",
            description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Soluta reiciendis deserunt doloribus consequatur, laudantium odio dolorum laboriosam.",
            image: "http://placehold.it/1000x500",
            alt: "Graphic"
          }
        ]
      };
    },
    methods: {
      startFade(index) {
        let element = this.$refs.containers[index];
        if (element && !element.classList.contains('fade-in')) {
          element.classList.add('fade-in');
        }
      },
      isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <= (window.innerHeight  || document.documentElement.clientHeight ) &&
          rect.right <= (window.innerWidth || document.documentElement.clientWidth )
        );
      }
    },
    mounted() {
      const containers = this.$refs.containers;
      containers.forEach((container, index) => {
        const handleScroll = () => {
          if (this.isElementInViewport(container)) {
            this.startFade(index);
            window.removeEventListener('scroll', handleScroll);
          }
        };
        window.addEventListener('scroll', handleScroll);
      });
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
    @import '@/assets/sass/partials/variables';
    @import '@/assets/sass/partials/generals';

  
  /* Media Queries */
  
  @mixin mq-xs {
    @media (min-width: 320px) {
      @content;
    }
  }
  
  @mixin mq-sm {
    @media (min-width: 480px) {
      @content;
    }
  }
  
  @mixin mq-md {
    @media (min-width: 720px) {
      @content;
    }
  }
  
  @mixin mq-lg {
    @media (min-width: 1000px) {
      @content;
    }
  }
  
  $background: transparent;
  $box-shadow: 0px 1px 22px 4px rgba(0, 0, 0, 0.07);
  $border: 1px solid $fourth_color;
  $items: 5;
  $rows: ceil(calc($items/2));
  
  /* Card sizing */
  
  $card-height: 400px;
  $card-width: 450px;
  $inner-margin: 15px;
  $number-size: 35px;
  $stagger: 180px;
  $outer-margin: 90px;
  $marker-size: 9px;
  
  /* Colors */
  
  $steps: #46b8e9;
  
  $colors: #ff5b00,
  #a3a3a3,
  #00ffda,
  $primary_color;
  
  $timeline: $third_color;
  
  /* Calculations */
  
  $container-height: $rows * ($card-height + $outer-margin) + $stagger;
  $container-width: $card-width*2 + $outer-margin*3;
  $head-height: $number-size + 50;
  $body-height: $card-height - $head-height;
  $marker-dist: $card-width + calc($outer-margin / 2) - calc($marker-size / 2);
  
  /* Placeholders */
  
  @include mq-lg {
    %arrow {
      position: absolute;
      content: "";
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
    }
    %marker {
      position: absolute;
      content: "";
      width: $marker-size;
      height: $marker-size;
      background-color: $timeline;
      border-radius: $marker-size;
      box-shadow: 0px 0px 2px 8px $background;
    }
  }
  
  
  /* Some Cool Stuff */
  
  $counter: $items - $rows + 2;
  @for $i from 1 through $rows {
    .demo-card:nth-child(#{$i*2-1})   { order: $i }
    .demo-card:nth-child(#{$i*2})     { order: $counter }
    $counter: $counter + 1;
  }
  
  /* Border Box */
  
  * {
    box-sizing: border-box;
  }
  
  /* Fonts */
  
  #timeline {
    padding: 100px 0;
    background: $background;
    border-top: $border;
    border-bottom: $border;
    h1 {
      text-align: center;
      font-size: 3rem;
      font-weight: 200;
      margin-bottom: 20px;
    }
    p{
      color: $fourth_color;
    }
    p.leader {
      text-align: center;
      max-width: 90%;
      margin: auto;
      margin-bottom: 45px;
    }
    .demo-card-wrapper {
      position: relative;
      margin: auto;
      @include mq-lg {
        display: flex;
        flex-flow: column wrap;
        width: $container-width;
        height: $container-height;
        margin: 0 auto;
      }
      &::after {
        z-index: 1;
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 50%;
        border-left: $border;
        @include mq-lg {
          border-left: 1px solid $timeline;
        }
      }
    }
    .demo-card {
      position: relative;
      display: block;
      margin: 10px auto 80px;
      max-width: 94%;
      z-index: 2;
      @include mq-sm {
        max-width: 60%;
        box-shadow: $box-shadow;
      }
      @include mq-md {
        max-width: 40%;
      }
      @include mq-lg {
        max-width: $card-width;
        height: $card-height;
        margin: $outer-margin;
        margin-top: calc($outer-margin / 2);
        margin-bottom: calc($outer-margin / 2);
        &:nth-child(odd) {
          margin-right: calc($outer-margin / 2);
          opacity: 0;
          transform: translateX(100%);
          .head::after {
            @extend %arrow;
            border-left-width: 15px;
            border-left-style: solid;
            left: 100%;
          }
          .head::before {
            @extend %marker;
            left: $marker-dist + 1;
          }
        }
        &:nth-child(odd).fade-in {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
        &:nth-child(even) {
          margin-left: calc($outer-margin / 2);
          opacity: 0;
          transform: translateX(-100%);
          .head::after {
            @extend %arrow;
            border-right-width: 15px;
            border-right-style: solid;
            right: 100%;
          }
          .head::before {
            @extend %marker;
            right: $marker-dist - 1;
          }
        }
        &:nth-child(2) {
          margin-top: $stagger;
        }
        &:nth-child(even).fade-in {
          opacity: 1;
          transform: translateX(0);
          transition: opacity 0.5s ease, transform 0.5s ease;
        }
      }
      .head {
        position: relative;
        display: flex;
        align-items: center;
        color: $third_color;
        font-weight: 400;
        .number-box {
          display: inline;
          float: left;
          margin: $inner-margin;
          padding: 10px;
          font-size: $number-size;
          line-height: $number-size;
          font-weight: 600;
          background: rgba(0, 0, 0, 0.17);
        }
        h2 {
          text-transform: uppercase;
          font-size: 1.3rem;
          font-weight: inherit;
          letter-spacing: 2px;
          margin: 0;
          padding-bottom: 6px;
          line-height: 1.4rem;
          @include mq-sm {
            font-size: 150%;
            line-height: 1.4rem;
          }
          span {
            display: block;
            font-size: 0.6rem;
            margin: 0;
            @include mq-sm {
              font-size: 0.8rem;
            }
          }
        }
      }
      .body {
        background: $third_color;
        color: $secondary_color;
        border: $border;
        border-top: 0;
        padding: $inner-margin;
        @include mq-lg {
          height: $body-height;
        }
        p {
          font-size: 14px;
          line-height: 18px;
          margin: $inner-margin auto;
        }
        img {
          display: block;
          width: 100%;
        }
      }
      @for $i from 1 through $items {
        &--step#{$i} {
          $color: nth($colors, ((($i - 1) % 4) + 1));
          background-color: $color;
          .head::after {
            border-color: $color;
          }
        }
      }
    }
  }
  </style>
  