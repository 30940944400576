<template>
    <header>
        <NavbarComponent/>
    </header>
</template>

<script>
import NavbarComponent from '@/components/HeaderComponents/NavbarComponent.vue';

export default {
    name: 'HeaderLayout',
    components: {
        NavbarComponent,
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';


header{
    // // Italian Gradient Border
    // border-top: 5px solid;
    // border-image-slice: 1;
    // border-width: 3px;
    // border-image-source: linear-gradient(to right, #008C45, #F4F5F0, #CD212A);

    background-color: black;

}

</style>