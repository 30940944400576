<template>
  <div id="contactform" class="mainbody">
    <iframe id="map-background" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7564.4998557316885!2d12.805080113102703!3d43.86908778881949!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132ce27fbcb77abd%3A0x201a9d20655aed83!2sGiesse%20Plast!5e0!3m2!1sit!2sit!4v1706463154537!5m2!1sit!2sit" style="border:0;" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    <section id="contact-container">
      <div class="contact">
        <form @submit.prevent="handleSubmit" id="contact-form">
          <div class="label-container">
            <label for="name">Nome e Cognome</label>
            <input v-model="name" type="text" id="name" name="name" required />
            <div class="errors-container" v-if="errors">
              <span class="error-message" v-if="errors.name"><i class="fas fa-exclamation-circle"></i> {{ errors.name[0] }}</span>
              <span v-else><i class="fas fa-check" style="color: green;"></i></span>
            </div>
          </div>
          <div class="label-container">
            <label for="email">Email</label>
            <input v-model="email" type="email" id="email" name="email" required />
            <div class="errors-container" v-if="errors">
              <span class="error-message" v-if="errors.email"><i class="fas fa-exclamation-circle"></i> {{ errors.email[0] }}</span>
              <span v-else><i class="fas fa-check" style="color: green;"></i></span>
            </div>
          </div>
          <div class="label-container">
            <label for="message">Messaggio</label>
            <textarea v-model="message" id="message" name="message" rows="6" required></textarea>
          </div>
          <div class="errors-container" v-if="errors">
            <span class="error-message" v-if="errors.message"><i class="rel-to fas fa-exclamation-circle"></i> {{ errors.message[0] }}</span>
            <span v-else><i class="fas fa-check" style="color: green;"></i></span>
          </div>
          <div class="checkbox-container">
            <label for="checkbox" class="checkbox-label">
              <input v-model="checkbox" type="checkbox" class="checkbox-input" id="checkbox" name="checkbox" required>
              <div class="checkbox-custom"></div>
              Dichiaro di avere preso visione dell'informativa sul trattamento dei <a href="#">dati personali</a>.
            </label>
          </div>
          <div class="errors-container" v-if="errors">
            <span class="error-message" v-if="errors.checkbox"><i class="rel-to fas fa-exclamation-circle"></i> {{ errors.checkbox[0] }}</span>
            <span v-else><i class="fas fa-check" style="color: green;"></i></span>
          </div>
          <button type="submit" id="submit-button" class="btn" :disabled="success || fail == true">
            <i id="send-badge" :class="{'d-none': loading || success || fail}" class="grow-pulse fas fa-paper-plane"></i>
            <i id="loading-badge" :class="{'d-none': !loading}" class="fas fa-circle-notch spin-badge"></i>
            <i id="success-badge" :class="{'d-none': !success}" class="fas fa-check"></i>
            <i id="fail-badge" :class="{'d-none': !fail}" class="fas fa-times"></i>
          </button>
          <div class="errors-container" v-if="axiosError">
            <span class="error-message"><i class="rel-to fas fa-exclamation-circle"></i> {{ axiosError }}</span>
          </div>
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import axios from '@/axios';

export default {
  name: 'ContactForm',
  data() {
    return {
      email: '',
      name: '',
      message: '',
      checkbox: false,
      errors: null,
      loading: false,
      success: false,
      fail: false,
      axiosError: null,
      throttleTimeout: 10000,
    };
  },
  methods: {
    async handleSubmit() {
      this.errors = null;
      this.loading = true;
      this.success = false;
      this.fail = false;

      try {
        const response = await axios.post('api/contact-us', {
          email: this.email,
          name: this.name,
          message: this.message,
          checkbox: this.checkbox,
        });

        if (response.data.success) {
          this.resetForm();
          this.success = true;
        } else {
          this.errors = response.data.errors;
          this.fail = true;
        }
      } catch (error) {
        this.handleError(error);
      } finally {
        this.loading = false;
      }
    },
    handleError(error) {
      if (error.response && error.response.status === 429) {
        this.axiosError = 'Hai superato il limite di richieste. Riprova tra poco.';
      } else if (error.response && error.response.data) {
        this.axiosError = error.response.data.message || 'Si è verificato un errore.';
      } else {
        this.axiosError = 'Si è verificato un errore sconosciuto.';
      }
      this.fail = true;
      setTimeout(() => {
        this.fail = false;
      }, this.throttleTimeout);
    },
    resetForm() {
      this.name = '';
      this.email = '';
      this.message = '';
      this.checkbox = false;
      this.errors = null;
      this.axiosError = null;
      setTimeout(() => {
        this.success = false;
      }, this.throttleTimeout);
    },
  },
  mounted() {
    this.checkbox = false;
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

.mainbody{
  margin: 0;
  overflow: hidden;
  position: relative;
}

#map-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  filter: grayscale(0.5) contrast(1) opacity(0.9);
}

#contact-container{
  position: relative;
  height: 100vh;
  width: 100%;
}

.contact {
  width: 30%;

  background-color: rgba($color: $secondary_color, $alpha: 0.7);
  border: 1px solid rgba($color: $primary_color, $alpha: 0.7);
  padding: 1.5rem;
  margin: 1.5rem;

  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.contact h1 {
  // font: 500 48px;
  font-size: 2.7rem;
  text-transform: capitalize;
  margin: 0 0 1.5vh 0;
  color: rgba($color: $primary_color, $alpha: 1);
}

.contact p {
  font: 500 16px;
  margin: 0 0 3vh 0;
  color: $third_color;
}

#contact-form {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0;
}

#contact-form label {
  font: 500 16px ;
  margin: 1.5vh 0;
}

#contact-form input, #contact-form textarea {
  outline: 1px solid;
  outline-color: rgba($color: $primary_color, $alpha: 0.5);
  border: 1px solid transparent;
  background-color: rgba($color: $secondary_color, $alpha: 0.7);
  font: 500 1.1rem;
  width: 100%;
  padding: 1.2vh 0.75em;
  // margin-bottom: 3.5vh;
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
          transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  
  color: $third_color;
}
#contact-form input:focus,
#contact-form textarea:focus,
#contact-form input:active,
#contact-form textarea:active {
  border: 1px solid rgba($color: $primary_color, $alpha: 0.7);
  box-shadow: inset 0 0 20px rgba($color: $primary_color, $alpha: 0.5), 0 0 10px rgba($color: $primary_color, $alpha: 0.2);
  text-shadow: 1px 1px 2px $primary_color;
}

#contact-form textarea {
  resize: none;
}

/* Checkbox style */
#contact-form .checkbox-container {
    cursor: pointer;
}

#contact-form .checkbox-input {
  position: absolute;
  opacity: 0;
}

#contact-form .checkbox-custom {
  position: absolute;
  top: 0.9vh;
  left: -30px;
  height: 15px;
  width: 15px;
  background-color: rgba($color: $secondary_color, $alpha: 0.7);
  border: 1px solid transparent;
  outline: 1px solid;
  outline-color: rgba($color: $primary_color, $alpha: 0.5);
  -webkit-transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
  transition: all 1250ms cubic-bezier(0.19, 1, 0.22, 1);
}

#contact-form label.checkbox-label {
  position: relative;
  display: inline-block;
  margin-left: 30px;
  margin-top: 0.5vh;
  font-size: 1.1rem;
  color: $third_color;
}

#contact-form .checkbox-input:checked + .checkbox-custom {
    background-color: rgba($color: $primary_color, $alpha: 0.3);
}

#contact-form .checkbox-input:focus + .checkbox-custom {
  box-shadow: 0 0 10px rgba($color: $primary_color, $alpha: 0.5);
}

#contact-form .checkbox-input:checked + .checkbox-custom::after {
    content: '\2713';
    font-size: auto;
    color: $third_color;
    position: absolute;
    top: -50%;
    left: 0;
    overflow: none;
}

/* Change Autocomplete styles in Chrome for checkbox */
#contact-form .checkbox-input:-webkit-autofill,
#contact-form .checkbox-input:-webkit-autofill:hover,
#contact-form .checkbox-input:-webkit-autofill:focus {
  background-color: rgba($color: $secondary_color, $alpha: 0.1);
  color: $third_color;
  -webkit-text-fill-color: $third_color;
  -webkit-box-shadow: 0 0 0px 1000px rgba($color: $secondary_color, $alpha: 1) inset;
  transition: background-color 5000s ease-in-out 0s;
}


.errors-container{
  margin: 0.5vh 0;
  color: rgba($color: $primary_color, $alpha: 1);
}

/* Change Autocomplete styles in Chrome*/
#contact-form input:-webkit-autofill,
#contact-form input:-webkit-autofill:hover, 
#contact-form input:-webkit-autofill:focus,
#contact-form textarea:-webkit-autofill,
#contact-form textarea:-webkit-autofill:hover, 
#contact-form textarea:-webkit-autofill:focus{
  border: 1px solid;
  background-color: rgba($color: $secondary_color, $alpha: 0.1);
  color: $third_color;
  -webkit-text-fill-color: $third_color;
  -webkit-box-shadow: 0 0 0px 1000px rgba($color: $secondary_color, $alpha: 1) inset;
  transition: background-color 5000s ease-in-out 0s;
}


#submit-button{
  margin: 1vh 0;

  & .grow-pulse{
    display: inline-block;
    transition-duration: 0.2s;
    transition-property: transform;
  }

  &:hover .grow-pulse,
  &:focus .grow-pulse{
    transform: scale(1.2);
  }
  &:active .grow-pulse{
    animation-name: pulse;
    animation-duration: 0.2s;
    animation-timing-function: linear;
  }
}
/* Animations */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  to {
    transform: scale(.8);
  }
}

.spin-badge {
  animation: spin 1s ease infinite;
}

.btn-unclickable {
  pointer-events: none;
}

@media screen and (max-width: 600px) {
  .contact {
    width: calc(100% - 3rem);
    // height: calc(100% - 3rem);
  }
  .contact h1 {
    font-size: 43px;
  }
  .contact p {
    font-size: 14.5px;
  }
  #contact-form {
    order: 1;
  }
  #contact-form input {
    font-size: 14.5px;
  }
  #contact-form label {
    font-size: 14.2px;
  }
  .social-media-icons {
    margin: 4.75vh 0 9vh;
  }
  .social-media-icons > a {
    margin: 0 4.25vw 0 0;
  }
  .social-media-icons > a > img {
    width: 3.2vw;
  }
  
}



.button-inner {
  display: flex;
  justify-content: center;
  position: relative;
  border-radius: 50px;
  height: 100%;
  line-height: 3em;
}

.button-text {
  transition: all .3s ease-in;
  opacity: 1;
}

.status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  
  width: 44px;
  height: 44px;
  
  border-radius: 50px;
  
  position: absolute;
  right: 3px;
  
  background-color: #2184E7;
  
  transition: all .3s ease-in;
}

.status-badge-expanded {
  width: 50px;
  height: 50px;
  right: 0px;
}

.badge {
  vertical-align: 0;
  position: absolute;
  
  transition: all .3s ease-in;
  opacity: 1;
}



.button-collapsed {
/*   animation: collapse-button .3s ease-in forwards; */
  width: 50px;
}

.translucent{
  opacity: 0;
}

.opaque {
  opacity: 1;
}
</style>