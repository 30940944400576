<template>
  <HeaderLayout/>
  <router-view/>
  <FooterLayout/>

  <ScrollToTopComponent/>
</template>

<script>
import HeaderLayout from '@/layouts/HeaderLayout.vue';
import FooterLayout from '@/layouts/FooterLayout.vue';
import ScrollToTopComponent from '@/components/Addons/ScrollToTopComponent.vue';

export default {
    name: 'App',
    components: {
      HeaderLayout,
      FooterLayout,
      ScrollToTopComponent,
    },
    watch:{
      $route (){
        // Make video end when route changes
        const videoPreview = document.getElementById('video-preview');
        const endVideoEvent = new Event('ended');
        
        videoPreview.dispatchEvent(endVideoEvent);
      }
    }
}
</script>

<style lang="scss">
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;600&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

    font-family: 'Montserrat', sans-serif;
}

body{
    background-color: $secondary_color !important;
    color: $third_color !important;
    overflow: scroll;
    overflow: overlay;
}
</style>
