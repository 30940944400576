<template>
    <main>
        <FirstPageText/>
        <CubeCarousel/>
        <OurNumbersComponent/>
        <OurServicesComponent/>
        
    </main>
</template>


<script>
import FirstPageText from '@/components/MainComponents/FirstPageText.vue';
import OurNumbersComponent from '@/components/MainComponents/OurNumbersComponent.vue';
import OurServicesComponent from '@/components/MainComponents/OurServicesComponent.vue';
import CubeCarousel from '@/components/Carousels/CubeCarousel.vue';

export default {
    name: 'HomePage',
    components: {
        FirstPageText,
        OurNumbersComponent,
        OurServicesComponent,
        CubeCarousel,
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';
</style>