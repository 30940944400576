<template>

    <section id="scrollhere" class="d-flex justify-content-center align-items-center" style="height: 100vh; text-align: center;">
        <div class="container-custom">
            <h1>{{ $route.meta.firstText }}</h1>
        </div>
    </section>

</template>

<script>
export default {
    name: 'FirstPageText',
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

* {
  /* Typography */
  --main-font: 'Montserrat', sans-serif;
  
  /* Calculation */
  --responsive: calc((var(--min-font) * 1px) + (var(--max-font) - var(--min-font)) * ((100vw - 420px) / (1200 - 420))); /* Ranges from 421px to 1199px */
}

h1 {
  /* Set max and min font sizes */
  --max-font: 20;
  --min-font: 15;
  line-height: 2em;
  font-weight: 400;
  font-family: var(--main-font);
  font-size: var(--responsive);
}
h3 {
   /* Set max and min font sizes */
  --max-font: 20;
  --min-font: 10;
  line-height: 2em;
  font-weight: 300;
  font-family: var(--main-font);
  font-size: var(--responsive);
}
p {
  font-family: sans-serif;
  margin: auto;
  width: fit-content;
  
  color: $fourth_color;
  /* Set max and min font sizes */
  --max-font: 20;
  --min-font: 14;
  font-size: var(--responsive);
}

@media (min-width: 1200px) {
  h1,
  p {
    font-size: calc(var(--max-font) * 1px);
  }
}

@media (max-width: 420px) {
  h1,
  p {
    font-size: calc(var(--min-font) * 1px);
  }
}

section {
    background-image: linear-gradient(180deg, $secondary-color 0%, black 53%, $secondary_color 93%);
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
</style>