<template>
    <section>
            <div class="container-custom">
                <div class="row">
                    <div class="section-head col-sm-12">
                        <h4 style="line-height: 2.5rem; font-weight: 300;">Perchè <span style="text-wrap: nowrap;"><span style="font-weight: 600;">GIESSE</span><span style="font-weight: 300;">PLAST ?</span></span></h4>
                        <p>Consultate i nostri numeri per una chiara testimonianza della nostra affidabilità, professionalità e capacità di produzione. Questi dati riflettono il nostro impegno verso l'eccellenza e nel rispondere completamente alle necessità dei nostri clienti.</p>
                    </div>
                    <div v-for="object in numbersObjectsArray" :key="object.id" ref="containers" class="col-lg-4 col-sm-6">
                        <div class="item"> <span class="icon feature_box"><i :class="object.icon"></i></span>
                            <h3 class="number"
                                :class="{ 'hide': object.value === 0 }">{{ object.value }}</h3>
                            <h6>{{object.title}}</h6>
                            <p>{{object.desc}}</p>
                        </div>
                    </div>
                </div>
            </div>

    </section>
</template>

<script>
export default {
    name: 'OurNumbersComponent',
    data() {
        return {
            numbersObjectsArray: [
                { 
                    id: 1, 
                    name: 'Numero 1', 
                    title: 'clienti soddisfatti',
                    desc: 'Grazie alle soluzioni personalizzate, alla professionalità, all\'esperienza e alla massima collaborazione che offriamo, ci impegniamo a soddisfare le esigenze dei nostri clienti e superare le loro aspettative. Garantiamo risultati eccellenti e duraturi, costruendo rapporti solidi basati sulla fiducia reciproca.',
                    icon: 'fas fa-users',
                    value: 0, 
                    limit: 300, 
                    intervalID: null 
                },
                { 
                    id: 2, 
                    name: 'Numero 2', 
                    title: 'centri di lavoro', 
                    desc: 'Offriamo una vasta gamma di centri di lavoro per soddisfare tutte le esigenze dei clienti, garantendo soluzioni personalizzate e professionali per assicurare qualità e precisione in ogni fase del processo.',
                    icon: 'fas fa-sitemap',
                    value: 0, 
                    limit: 50, 
                    intervalID: null 
                },
                { 
                    id: 3, 
                    name: 'Numero 3', 
                    title: 'paesi in cui operiamo', 
                    desc: 'La nostra azienda ha consolidato una presenza globale con una clientela distribuita in diversi paesi del mondo. Attualmente operiamo in diverse nazioni, fornendo soluzioni su misura per una vasta gamma di settori, garantendo così un servizio affidabile e competitivo a livello internazionale.',
                    icon: 'fas fa-globe-americas',
                    value: 0, 
                    limit: 4, 
                    intervalID: null 
                },
                { 
                    id: 4, 
                    name: 'Numero 4', 
                    title: 'Articoli prodotti', 
                    desc: 'I nostri articoli prodotti.',
                    icon: 'fas fa-star',
                    value: 0, 
                    limit: 1000, 
                    intervalID: null 
                },
                { 
                    id: 5, 
                    name: 'Numero 5', 
                    title: 'progetti realizzati', 
                    desc: 'Attraverso la nostra versatilità e competenza abbiamo realizzato ed ultimato una vasta gamma di progetti realizzati con precisione, innovazione ed attenzione ai dettagli.',
                    icon: 'fas fa-pencil-ruler',
                    value: 0, 
                    limit: 500, 
                    intervalID: null 
                },
                { 
                    id: 6, 
                    name: 'Numero 6', 
                    title: 'Settori merceologici', 
                    desc: 'Operiamo in diversi settori come: Imballaggi alimentari, imballaggi industriali, elettronica, automotive, edile e altri, per una diversificata e completa competenza di prodotti e servizi.',
                    icon: 'fas fa-list-ol',
                    value: 0, 
                    limit: 12, 
                    intervalID: null 
                },
            ],
        };
    },
    methods: {
        startIncrement(index) {
            let object = this.numbersObjectsArray[index];
            let objectInterval = 3900 / object.limit;

            if (!object.intervalID) {
                object.intervalID = setInterval(() => {
                    if (object.value < object.limit) {
                        object.value++;
                    } else {
                        clearInterval(object.intervalID);
                    }
                }, objectInterval );
            }
        },
        isElementInViewport(el) {
        const rect = el.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight  || document.documentElement.clientHeight ) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth )
        );
        }
    },
    mounted() {
        const containers = this.$refs.containers;
        containers.forEach((container, index) => {
        const handleScroll = () => {
            if (this.isElementInViewport(container)) {
            this.startIncrement(index);
            window.removeEventListener('scroll', handleScroll);
            }
        };
        window.addEventListener('scroll', handleScroll);
        });
    },
    beforeUnmount() {
        this.numbersObjectsArray.forEach(object => {
            if (object.intervalID) {
                clearInterval(object.intervalID);
            }
        });
    }
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';

.hide{
    visibility: hidden;
}

a,
a:hover,
a:focus,
a:active {
    text-decoration: none;
    outline: none;
}
        
a,
a:active,
a:focus {
    color: $primary_color;
    text-decoration: none;
    transition-timing-function: ease-in-out;
    -ms-transition-timing-function: ease-in-out;
    -moz-transition-timing-function: ease-in-out;
    -webkit-transition-timing-function: ease-in-out;
    -o-transition-timing-function: ease-in-out;
    transition-duration: .2s;
    -ms-transition-duration: .2s;
    -moz-transition-duration: .2s;
    -webkit-transition-duration: .2s;
    -o-transition-duration: .2s;
}
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
img {
    max-width: 100%;
    height: auto;
}
span, a, a:hover {
    display: inline-block;
    text-decoration: none;
    color: inherit;
}
.section-head {
  margin: 60px auto;
}
.section-head h4 {
  position: relative;
  padding:0;
  color: $third_color;
  line-height: 1;
  letter-spacing:0.3px;
  font-size: 34px;
  font-weight: 700;  
  text-align:center;
  text-transform:none;
  margin-bottom:30px;
}
.section-head h4:before {
  content: '';
  width: 60px;
  height: 2px;
  background: $primary_color;
  position: absolute;
  left: 0px;
  bottom: -10px;
  right:0;  
  margin:0 auto;
}
.section-head h4 span {
  font-weight: 700;
  padding-bottom: 5px;
  color:$third_color;
}
.section-head p, p.awesome_line{
  color:$fourth_color;
  font-size:16px;
  line-height:28px;
  text-align:center;
}

.extra-text {
    font-size:34px;
    font-weight: 700;
    color:$secondary_color;
    margin-bottom: 25px;
    position:relative;
    text-transform: none;
}
.extra-text::before {
    content: '';
    width: 60px;
    height: 3px;
    background: blue;
    position: absolute;
    left: 0px;
    bottom: -10px;
    right: 0;
    margin: 0 auto;
}
.extra-text span {
    font-weight: 700;
    color:$primary_color;
}
.item {
    background: transparent;
    text-align: center;
    padding: 30px 25px;
    -webkit-box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    box-shadow:0 0px 25px rgba(0, 0, 0, 0.07);
    border-radius: 0;
    border: 3px solid rgba($primary_color, 0.3);
    margin: 15px;
    -webkit-transition: all .9s ease 0;
    transition: all .9s ease 0;
    transition: all 0.9s ease 0s;
    height: 90%;
}
.item:hover{
    background:$primary_color;
    box-shadow:0 8px 20px 0px rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.9s ease 0;
    transition: all 0.9s ease 0;
    transition: all 0.9s ease 0s;
}
.item:hover .item, .item:hover span.icon{
    background: $third_color;
    border-radius:0;
    -webkit-transition: all 0.9s ease 0;
    transition: all 0.9s ease 0;
    transition: all 0.9s ease 0s;
}
.item:hover h6, .item:hover p{
    color:$third_color;
    -webkit-transition: all 0.9s ease 0;
    transition: all 0.9s ease 0;
    transition: all 0.9s ease 0s;
}
.item .icon {
    font-size: 40px;
    margin-bottom:25px;
    color: $third_color;   
    width: 90px;
    height: 90px;
    line-height: 96px;
    border-radius: 50px;
}
.item .feature_box{
    background:rgba($third_color, 0.05);
    color:$primary_color;
}
.item p{
    font-size:15px;
    line-height:26px;
    color: $fourth_color;
}
.item h6,
.item h3 {
    text-transform: capitalize;
    margin-bottom:20px;
    font-weight: 600;
    color:$third_color;
}
.mission p {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 28px;
    font-weight: 500;
}
.mission i {
    display: inline-block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background: $primary_color;
    border-radius: 50%;
    color: $third_color;
    font-size: 25px;
}
.mission .small-text {
    margin-left: 10px;
    font-size: 13px;
    color: $fourth_color;
}
.skills {
    padding-top:0px;
}
.skills .prog-item {
    margin-bottom: 25px;
}
.skills .prog-item:last-child {
    margin-bottom: 0;
}
.skills .prog-item p {
    font-weight: 500;
    font-size: 15px;
    margin-bottom: 10px;
}
.skills .prog-item .skills-progress {
    width: 100%;
    height: 10px;
    background: #e0e0e0;
    border-radius:20px;
    position: relative;
}
.skills .prog-item .skills-progress span {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: $primary_color;
    width: 10%;
    border-radius: 10px;
    -webkit-transition: all 1s;
    transition: all 1s;
}
.skills .prog-item .skills-progress span:after {
    content: attr(data-value);
    position: absolute;
    top: -5px;
    right: 0;
    font-size: 10px;
    font-weight:600;
    color: $third_color;
    background:rgba($secondary_color, 0.9);
    padding: 3px 7px;
    border-radius: 30px;
}

</style>