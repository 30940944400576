<template>
  <main>
      <FirstPageText/>
      <ContactForm/>
  </main>
</template>


<script>
import FirstPageText from '@/components/MainComponents/FirstPageText.vue';
import ContactForm from '@/components/Forms/ContactForm.vue';

export default {
  name: 'ContactUsPage',
  components: {
    FirstPageText,
    ContactForm,
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/partials/variables';
@import '@/assets/sass/partials/generals';
</style>